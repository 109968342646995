/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback } from 'react';
import type { HookFetcher } from '../utils/types';
import { CommerceError } from '../utils/errors';
import useCommerceLogin from '../commerce/use-login';
import type { LoginBody, LoginResponseBody } from '../api/auth';
import useCustomer from '../customer/use-customer';

const defaultOpts = {
  url: '/api/maxify/auth',
  method: 'PUT',
};

export type LoginInput = LoginBody;
export type LoginResult = LoginResponseBody;

export const fetcher: HookFetcher<LoginResponseBody, LoginBody> = (
  options,
  { email, password, oauthUID },
  fetch
) => {
  if (!(email && password)) {
    throw new CommerceError({
      message: 'An email and password are required to login.',
    });
  }

  if (!oauthUID) {
    throw new CommerceError({
      message: 'Invalid request.',
    });
  }

  return fetch({
    ...defaultOpts,
    ...options,
    body: { email, password, oauthUID },
  });
};

export function extendHook(customFetcher: typeof fetcher) {
  const useLogin = () => {
    const { mutate } = useCustomer();
    const fn = useCommerceLogin<LoginResult, LoginInput>(
      defaultOpts,
      customFetcher
    );

    return useCallback(
      async function login(input: LoginInput) {
        const data = await fn(input);
        await mutate();
        return data;
      },
      [fn, mutate]
    );
  };

  useLogin.extend = extendHook;

  return useLogin;
}

export default extendHook(fetcher);
