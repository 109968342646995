import { classnames } from '@tailwindcss-classnames';

export const relative = classnames('relative');
export const container = classnames('ml-3', relative);
export const flexCenter = classnames('flex', 'items-center');
export const legalText = classnames('text-xs', 'px-6');
export const textCenter = classnames('text-center');

const bgWhite = classnames('bg-white');
const bgGrey800 = classnames('bg-nzxt-dark-grey-800');
const textWhite = classnames('text-white');

export const policyLink = classnames('pl-0', 'pr-0', 'text-xs', 'underline');
export const loginLinksBase = classnames('pl-0', 'pr-0', 'text-sm');

export const loginNotficationLinks = classnames(
  'pl-1',
  'text-sm',
  'font-semibold',
  'hover:underline',
  'focus:underline',
  'inline-flex'
);

export const alertLink = classnames(
  loginNotficationLinks,
  'underline',
  '!text-white'
);

export const loginSignUpWith = classnames('ml-2');

const whiteLogo = classnames(textWhite, 'fill-current');
export const logo = (isInverted?: boolean): string =>
  classnames('w-20', 'h-6', 'mx-auto', {
    [whiteLogo]: isInverted,
  });

export const loginWrapper = (isInverted?: boolean): string =>
  classnames(
    'flex',
    'flex-col',
    'justify-center',
    'py-8',
    'sm:px-6',
    'lg:px-8',
    {
      [bgGrey800]: isInverted,
      [bgWhite]: !isInverted,
    }
  );

export const standaloneWrapper = classnames(
  'flex',
  'flex-col',
  'justify-center',
  'py-8',
  'sm:px-6',
  'lg:px-8',
  'm-auto',
  'max-w-lg',
  'lg:py-12'
);

export const loginTopCard = classnames(
  'sm:mx-auto',
  'sm:w-full',
  'sm:max-w-md'
);

export const loginHeading = classnames(
  'mt-6',
  'text-center',
  'text-3xl',
  'font-extrabold',
  'text-nzxt-light-grey-800'
);

export const loginSubheading = classnames(
  'text-center',
  'text-sm',
  'text-nzxt-light-grey-600',
  'w-max',
  'mx-auto',
  'flex',
  'items-center',
  'mt-4'
);

export const loginBottomCard = classnames(
  'sm:mx-auto',
  'sm:w-full',
  'sm:max-w-md'
);

export const loginFormWrapper = classnames('pt-4', 'md:pt-8', 'sm:px-10');

export const loginForm = classnames('space-y-6', 'mt-6');

export const loginButtonWrapper = classnames(
  'flex',
  'justify-center',
  'w-full'
);
export const loginButton = classnames('w-full');
export const loginCheckboxWrapper = classnames(flexCenter, 'justify-between');

export const loginContinueWithWrapper = classnames('mt-6');

export const loginSocialSignInWrapper = classnames(
  'mt-6',
  'flex',
  'justify-center',
  'px-4',
  'sm:px-10'
);

export const loginSvgContainer = classnames(
  'w-full',
  'inline-flex',
  'justify-center',
  'py-2',
  'border',
  'border-gray-300',
  'rounded-md',
  'shadow-sm',
  'bg-white',
  'text-nzxt-light-grey-600',
  'hover:bg-nzxt-light-grey-50'
);

export const loginSvgStyles = classnames(
  'w-6',
  'h-6',
  'stroke-current',
  'text-nzxt-light-grey-600'
);

export const loginSignUpWrapper = classnames(
  'mt-4',
  'flex',
  'items-center',
  'justify-center'
);

export const loginSignUpText = (isInverted?: boolean): string =>
  classnames('mr-2', 'text-sm', {
    [textWhite]: isInverted,
  });

export const errorWrapper = classnames('bg-error', 'p-3', 'rounded-md');
export const errorMessage = classnames(
  'text-white',
  'text-sm',
  'font-semibold'
);

export const loginSuccessWrapper = classnames(
  'm-16',
  'sm:mx-auto',
  'sm:w-full',
  'sm:max-w-md'
);

export const checkMark = classnames('w-16', 'py-2', 'mx-auto');

export const successMessage = (isInverted?: boolean): string =>
  classnames('mt-2', 'text-center', 'text-sm', 'font-semibold', {
    [textWhite]: isInverted,
  });

export const successButtonWrapper = classnames(loginButtonWrapper, 'mt-6');
