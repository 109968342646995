import { FC, memo } from 'react';
import * as styles from './styles';

type DividerProps = {
  text?: string;
  isInverted?: boolean;
};

const Divider: FC<DividerProps> = ({ text, isInverted }) => (
  <div className={styles.relative}>
    <div className={styles.dividerWrapper} aria-hidden="true">
      <div className={styles.divider} />
    </div>
    {text && (
      <div className={styles.dividerTextWrapper}>
        <span className={styles.dividerText(isInverted)}>{text}</span>
      </div>
    )}
  </div>
);

export default memo(Divider);
