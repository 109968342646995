import { FC, memo, ReactNode, useMemo, HTMLProps, RefObject } from 'react';
import cx from 'clsx';
import { IconLoading } from '@nzxt/react-icons';
import ForwardRefBaseButton from './ForwardRefBase';
import * as styles from './styles';
import buttonStyles from './buttonStyles.module.css';

type Props = {
  children: ReactNode;
  buttonStyle: string;
  className?: string;
  dark?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  customMargin?: boolean;
  buttonRef?: RefObject<HTMLButtonElement | null>;
};

type ButtonProps = Props & HTMLProps<HTMLButtonElement>;
// TODO: The forward ref has the following type error:
// Type 'LegacyRef<HTMLButtonElement>' is not assignable to type 'Ref<HTMLButtonElement>'.
// Type 'string' is not assignable to type 'Ref<HTMLButtonElement>'.ts(2322)
// It is unclear why this issue is persisting @Donny @Tatiana

const Button: FC<ButtonProps> = ({
  children,
  buttonStyle,
  className,
  dark,
  type,
  loading,
  customMargin,
  buttonRef,
  ...rest
}) => {
  const isText = buttonStyle.includes('text');

  const composedClassName = useMemo(
    () =>
      cx(
        styles.button(customMargin),
        dark
          ? styles.buttonModeDark[buttonStyle]
          : styles.buttonModeLight[buttonStyle],
        className,
        { [buttonStyles.btn]: !isText }
      ),
    [buttonStyle, className, dark, customMargin, isText]
  );
  return buttonRef ? (
    <ForwardRefBaseButton
      // eslint-disable-next-line
      // @ts-ignore
      ref={buttonRef}
      className={composedClassName}
      type={type}
      {...rest}
    >
      {loading ? <IconLoading className="loading-icon" /> : children}
    </ForwardRefBaseButton>
  ) : (
    // eslint-disable-next-line
    <button className={composedClassName} type={type} {...rest}>
      {loading ? <IconLoading className="loading-icon" /> : children}
    </button>
  );
};

export default memo(Button);
