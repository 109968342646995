/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable max-len */
import { FC, useState, memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  // FormProvider,
  // SubmitHandler,
  useForm,
} from 'react-hook-form';
import { IconNzxtLogo, IconGoogle } from '@nzxt/react-icons';
import useResendVerify from '@framework/auth/use-resend-verify';
// import { setUserEmailCookie } from '@utils/user-email-cookie';
import useUiStore, {
  getSetModalView,
  getCloseModal,
  // getModalAction,
  getSetModalOnCloseAction,
} from '@stores/use-ui-store';
// import useAuthStore from '@stores/use-auth-store';
import {
  useTranslation as t,
  useFormatMessage,
  useTimeout,
  // useAuth,
} from '@utils/hooks';
// import GTM from '@utils/gtm';
import useLogin from '@framework/auth/use-login';
import type { LoginBody } from '@framework/api/auth';
import {
  // CommerceError,
  ERROR_CODES,
  getErrorMessageKey,
} from '@framework/utils/errors';
import {
  ROUTE_OAUTH,
  // ROUTE_BUILD,
  // ROUTE_BUILD_PC,
  // ROUTE_BUILD_KEYBOARD,
  // ROUTE_CATEGORY,
} from '@constants';
import Button from '@components/Button';
import ButtonLink from '@components/ButtonLink';
import Input from '@components/Input';
import Divider from '@components/Divider';
// import CheckboxInput from '@components/CheckboxInput';
import Checkmark from '@components/Checkmark';
import * as styles from './styles';

type Props = {
  pageType: string;
  isInverted?: boolean;
};

export const federatedSignIn = async (
  provider: string,
  oauthUID: string,
  options: { callbackUrl?: string; json?: string; redirect?: string }
): Promise<void> => {
  const {
    callbackUrl = window.location.href,
    // redirect = true
  } = options ?? {};
  const signInUrl = `/uaa/oidc/interaction/${oauthUID}/federated/${provider}`;

  const res = await fetch(signInUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },

    body: new URLSearchParams({
      ...options,
      callbackUrl,
      json: 'true',
    }),
  });

  const data = await res.json();
  const url = data.url ?? callbackUrl;
  window.location.href = url;
};

const LoginView: FC<Props> = ({ pageType, isInverted }) => {
  const { query, push } = useRouter();
  const { slug, client_id: rawClientId } = query;
  const oauthUID =
    Array.isArray(slug) && slug.length === 2 ? slug[1] : undefined;
  const clientId = Array.isArray(rawClientId) ? rawClientId[0] : rawClientId;
  // const login = useAuthStore.useLogin();

  const [loading, setLoading] = useState(false);
  const [showVerificationLink, setShowVerificationLink] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [
    resendEmailVerificationSuccesMessage,
    setResendEmailVerificationSuccesMessage,
  ] = useState('');
  const [callbackUrl, setCallbackUrl] = useState(
    pageType === 'modal'
      ? window.location.href
      : process.env.NEXT_PUBLIC_BASE_URL
  );

  // const { isLoggedIn } = useAuth();
  const logIn = useLogin();

  const setModalView = useUiStore(getSetModalView);
  const closeModal = useUiStore(getCloseModal);
  // const modalAction = useUiStore(getModalAction);
  const setModalOnCloseAction = useUiStore(getSetModalOnCloseAction);

  const resendEmailVerification = useResendVerify();

  const f = useFormatMessage();

  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const { isDirty, isSubmitted, errors } = formState;

  useEffect(() => {
    if (isDirty && isSubmitted && errors.serverError) {
      clearErrors('serverError');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitted, errors]);

  const [delay, setDelay] = useState(null);

  const successDelayCallback = (): void => {
    /*
      if (pageType === 'modal') {
        modalAction();
      }
      */
    if (callbackUrl !== window.location.href) {
      push(callbackUrl);
    }
  };
  useTimeout(successDelayCallback, delay);

  setModalOnCloseAction(successDelayCallback);

  useEffect(() => {
    if (query.callbackUrl) {
      setCallbackUrl(
        Array.isArray(query.callbackUrl)
          ? query.callbackUrl[0]
          : query.callbackUrl
      );
    }
  }, [query.callbackUrl]);

  /*
  const gtmConfigAccountLogin = (): void => {
    const invalidUrl = [`/${ROUTE_CATEGORY}/gaming-pcs/`];
    const validUrl = [
      `/${ROUTE_BUILD}`,
      `${ROUTE_BUILD}/${ROUTE_BUILD_PC}/`,
      `/${ROUTE_BUILD}/${ROUTE_BUILD_PC}/${query}/`,
      `/${ROUTE_BUILD}/${ROUTE_BUILD_KEYBOARD}/`,
      `/${ROUTE_BUILD}/${ROUTE_BUILD_KEYBOARD}/${query}/`,
    ];

    const isValidPath = validUrl.some(currentPage =>
      asPath.includes(currentPage)
    );
    const isInValidPath = invalidUrl.some(currentPage =>
      asPath.includes(currentPage)
    );

    if (!isValidPath) {
      return;
    }
    if (isInValidPath) {
      return;
    }
    GTM.dataLayer({
      dataLayer: {
        event: 'Config_AccountLogin',
      },
    });
  };
  */

  const resendEmailVerificationHandler = async (): Promise<void> => {
    const email = getValues('email');
    try {
      setLoading(true);
      await resendEmailVerification({ email });
      clearErrors();
      setResendEmailVerificationSuccesMessage(
        f('user_sign_up_check_email_message')
      );
      setSuccessMessage(f('user_sign_up_success_verify_message'));
    } catch ({ errors }) {
      let errorMessage = f('error_generic');
      const errorMessageKey = getErrorMessageKey(errors);
      if (errorMessageKey) {
        window.heap?.track(errorMessageKey);
        errorMessage = f(errorMessageKey);
      }
      setError('serverError', {
        type: 'error',
        message: errorMessage,
      });
    } finally {
      setShowVerificationLink(false);
      setLoading(false);
    }
  };

  const onSubmit = async (data: LoginBody): Promise<void> => {
    try {
      setLoading(true);

      const res = await logIn({
        // redirect: false,
        // callbackUrl,
        email: data.email,
        password: data.password,
        remember_me: data.remember_me,
        oauthUID,
      });

      clearErrors();
      setShowVerificationLink(false);
      setLoading(false);
      // gtmConfigAccountLogin();
      setSuccessMessage(f('user_login_success_message'));
      setCallbackUrl(res.redirectTo);
      // setUserEmailCookie(data.email);
      if (pageType !== 'modal') {
        setDelay(2000);
      }
    } catch ({ errors }) {
      let errorMessage = f('error_generic');
      const errorMessageKey = getErrorMessageKey(errors);
      if (errorMessageKey) {
        window.heap?.track(errorMessageKey);
        errorMessage = f(errorMessageKey);
      }
      const formValues = getValues();
      // react-hook-form doesn't support dirtySinceLastSubmit so have to reset
      reset(formValues);
      setError('serverError', {
        type: 'error',
        message: errorMessage,
      });
      if (errors?.[0]?.code === ERROR_CODES.EmailNotVerified) {
        setShowVerificationLink(true);
      } else {
        setShowVerificationLink(false);
      }
      setLoading(false);
    }
  };

  const MESSAGE_INVALID_EMAIL = t('invalid_email');

  return (
    <div
      className={
        pageType === 'standalone'
          ? styles.standaloneWrapper
          : styles.loginWrapper(isInverted)
      }
    >
      <div className={styles.loginTopCard}>
        <IconNzxtLogo className={styles.logo(isInverted)} />
      </div>

      {successMessage && (
        <div className={styles.loginSuccessWrapper}>
          <div className={styles.checkMark}>
            <Checkmark />
          </div>
          {resendEmailVerificationSuccesMessage && (
            <p className={styles.successMessage(isInverted)}>
              {resendEmailVerificationSuccesMessage}
            </p>
          )}
          <p className={styles.successMessage(isInverted)}>{successMessage}</p>
          {pageType === 'modal' && (
            <div className={styles.successButtonWrapper}>
              <Button
                type="button"
                buttonStyle="primary"
                onClick={() => closeModal()}
              >
                {t('ok')}
              </Button>
            </div>
          )}
        </div>
      )}

      {!successMessage && (
        <div className={styles.loginBottomCard}>
          <div className={styles.loginSocialSignInWrapper}>
            <Button
              type="button"
              buttonStyle="textButton"
              className={styles.loginSvgContainer}
              data-test-id="google-sign-in"
              onClick={() =>
                federatedSignIn('google', oauthUID, { callbackUrl })
              }
            >
              <IconGoogle className={styles.loginSvgStyles} />
              <span className={styles.loginSignUpWith}>
                {t('user_login_text')}
              </span>
            </Button>
          </div>

          <div className={styles.loginFormWrapper}>
            <Divider text={t('user_email_sign_in')} isInverted={isInverted} />

            <form
              className={styles.loginForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              {errors.serverError && (
                <div className={styles.errorWrapper}>
                  <p className={styles.errorMessage}>
                    <>
                      {errors.serverError?.message}
                      {showVerificationLink && (
                        <Button
                          type="button"
                          buttonStyle="textButton"
                          className={styles.alertLink}
                          onClick={resendEmailVerificationHandler}
                        >
                          {t('here')}
                        </Button>
                      )}
                    </>
                  </p>
                </div>
              )}
              <div>
                <Input
                  data-test-id="email"
                  type="email"
                  autoComplete="email"
                  inputLabel={t('label_email_address')}
                  labelFor="emailAddress"
                  {...register('email', {
                    required: true,
                    // eslint-disable-next-line
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  requiredError={
                    errors.email && errors.email.type === 'required'
                  }
                  patternError={
                    errors.email &&
                    errors.email.type === 'pattern' &&
                    MESSAGE_INVALID_EMAIL
                  }
                  isInverted={isInverted}
                />
              </div>
              <div>
                <Input
                  data-test-id="password"
                  type="password"
                  autoComplete="current-password"
                  inputLabel={t('label_password')}
                  labelFor="password"
                  {...register('password', {
                    required: true,
                  })}
                  requiredError={
                    errors.password && errors.password.type === 'required'
                  }
                  isInverted={isInverted}
                />
              </div>
              <div className={styles.loginCheckboxWrapper}>
                {/*
                <div className={styles.flexCenter}>
                  <CheckboxInput
                    data-test-id="remember-me"
                    labelFor="remember_me"
                    checkBoxLabel={t('user_login_remember')}
                    {...register('remember_me')}
                    isInverted={isInverted}
                  />
                </div>
                */}
                <div>
                  {pageType === 'standalone' ? (
                    <ButtonLink
                      href={`/${ROUTE_OAUTH}/forgot-password`}
                      className={styles.loginLinksBase}
                      internalLink
                    >
                      {t('user_login_forgot_password')}
                    </ButtonLink>
                  ) : (
                    <Button
                      type="button"
                      buttonStyle="textButton"
                      className={styles.loginLinksBase}
                      onClick={() => setModalView('FORGOT_VIEW')}
                    >
                      {t('user_login_forgot_password')}
                    </Button>
                  )}
                </div>
              </div>

              <div className={styles.loginButtonWrapper}>
                <Button
                  type="submit"
                  buttonStyle="primary"
                  loading={loading}
                  className={styles.loginButton}
                >
                  {t('user_sign_in')}
                </Button>
              </div>
            </form>

            <div className={styles.loginContinueWithWrapper}>
              <div className={styles.loginSignUpWrapper}>
                <p className={styles.loginSignUpText(isInverted)}>
                  {t('user_no_account')}
                </p>
                {pageType === 'standalone' ? (
                  <ButtonLink
                    href={`/${ROUTE_OAUTH}/signup/${
                      clientId ? `?client_id=${clientId}` : ''
                    }`}
                    className={styles.loginLinksBase}
                    internalLink
                  >
                    {t('user_create_account')}
                  </ButtonLink>
                ) : (
                  <Button
                    type="button"
                    buttonStyle="textButton"
                    onClick={() => setModalView('SIGNUP_VIEW')}
                    className={styles.loginLinksBase}
                  >
                    {t('user_create_account')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(LoginView);
