import React, { memo, FC, useRef, useState } from 'react';

type Props = {
  src: string;
  loop?: boolean;
};

const LottieControl: FC<Props> = ({ src, loop = false }) => {
  const ref = useRef(null);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  React.useEffect(() => {
    const loadLottiePlayer = async (): Promise<void> => {
      await import('@lottiefiles/lottie-player');
      setPlayerLoaded(true);
    };

    loadLottiePlayer();
  }, []);
  return (
    playerLoaded && (
      <lottie-player
        ref={ref}
        autoplay
        mode="normal"
        src={src}
        {...(loop && { loop: true })}
      />
    )
  );
};

export default memo(LottieControl);
