import { classnames } from '@tailwindcss-classnames';

export const relative = classnames('relative');
export const flexCenter = classnames('flex', 'items-center');
export const dividerWrapper = classnames('absolute', 'inset-0', flexCenter);
export const divider = classnames('w-full', 'border-t', 'border-gray-300');
export const dividerTextWrapper = classnames(
  'relative',
  'flex',
  'justify-center',
  'text-sm'
);

const lightDivider = classnames('bg-white', 'text-gray-500');
const darkDivider = classnames('bg-nzxt-dark-grey-800', 'text-white');

export const dividerText = (isInverted?: boolean): string =>
  classnames('px-2', {
    [darkDivider]: isInverted,
    [lightDivider]: !isInverted,
  });
