/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback } from 'react';
import type { HookFetcher } from '../utils/types';
import { CommerceError } from '../utils/errors';
import useCommerceResendVerify from '../commerce/use-resend-verify';
import type { ResendVerifyBody, ResendVerifyResponseBody } from '../api/auth';
import useCustomer from '../customer/use-customer';

const defaultOpts = {
  url: '/api/maxify/auth',
  method: 'POST',
};

export type ResendVerifyInput = ResendVerifyBody;

export type ResendVerifyResult = ResendVerifyResponseBody;

export const fetcher: HookFetcher<
  ResendVerifyResponseBody,
  ResendVerifyBody
> = (options, { email }, fetch) => {
  if (!email) {
    throw new CommerceError({
      message: 'An email is required to request verification link.',
    });
  }

  return fetch({
    ...defaultOpts,
    ...options,
    body: { email },
  });
};

export function extendHook(customFetcher: typeof fetcher) {
  const useResendVerify = () => {
    const { mutate } = useCustomer();
    const fn = useCommerceResendVerify<ResendVerifyResult, ResendVerifyInput>(
      defaultOpts,
      customFetcher
    );

    return useCallback(
      async function signup(input: ResendVerifyInput) {
        const data = await fn(input);
        await mutate();
        return data;
      },
      [fn, mutate]
    );
  };

  useResendVerify.extend = extendHook;

  return useResendVerify;
}

export default extendHook(fetcher);
